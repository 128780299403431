const eNavBar = document.querySelector(".expertise-nav")
const scrollContainer = document.querySelector(".scroll-container")
const sections = document.getElementsByClassName("scroll-item")

const options = {
    // root: document.querySelector('.scroll-container'),
    rootMargin: '0px',
    threshold: .7
}

const updateHistory = (hash) => {
    clearTimeout(updateHistory.timeout)
    updateHistory.timeout = setTimeout(function () {
      if (window.location.hash !== hash) {
        history.pushState({}, window.title, hash)
      }
    }, 1000)
}

const toggleActiveTab = (sectionId) => {
    // console.log(Array.from(eNavBar.children).filter(item => item.id === `nav-${sectionId}`))
    const navTabs = Array.from(eNavBar.children)
    const activeNavTab = navTabs.filter(item => item.id === `nav-${sectionId}`)[0]

    navTabs.forEach(tab => tab.classList.remove('is-active'))
    activeNavTab.classList.add('is-active')
}
  
const callback = (entries) => {
    for (const entry of entries) {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
            console.log(entry.target.id)
            const hash = '#' + entry.target.id
            const sectionId = entry.target.id
            toggleActiveTab(sectionId)
            updateHistory(hash)
        }
    }
    
    // console.log(entries[0].target.id)
    
}

const initObserver = () => {
    const observer = new IntersectionObserver(callback, options);
    Array.from(sections).forEach(section => {
        observer.observe(section)
    })
}

if(eNavBar && scrollContainer) {
    // Settimeout for when routing to specific hash...
    setTimeout(() => {
        initObserver()
    }, 2000);

    
    // console.log('url match:', /^#../.test(lastSlug))

    // // If ID present in URL navigate to specified expertise section...
    // if(/^#../.test(lastSlug)) {
    //     console.log('url match:', lastSlug)
    //     const element = document.querySelector(lastSlug)
    //     console.log('url match:', element)
    //     element.scrollIntoView({behavior: "auto", block: "start"})
    // }

} 

