const getScrollBarWidth = () => {
    var scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    console.warn(scrollbarWidth); // Mac:  15

    // Delete the DIV 
    document.body.removeChild(scrollDiv);

    return scrollbarWidth;
}

export { getScrollBarWidth as default }