import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import getScrollBarWidth from "../_utils/scrollbar-width";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { searchBox, hits, stats } from "instantsearch.js/es/widgets";

const algoliaIndex = window.algoliaIndex;
const algoliaAppId = window.algoliaAppId;
const algoliaSearchKey = window.algoliaSearchKey;

const searchBtns = document.querySelectorAll(".search-button");
const searchWrap = document.querySelector(".search-wrap");
const backButton = document.querySelector(".back-button");
const barWidth = getScrollBarWidth();
const isHomePage = document.querySelector(".home-search-wrap");

// const setPaddingRightForScroll = (item) =>  {
//     const isScrollable = searchWrap.style.overflowY === 'scroll';
//     isScrollable ? item.style.paddingRight = `${barWidth}px` : item.style.paddingRight = `0px`
// }

const toggleShowSearch = () => {
  const searchList = searchWrap.classList;
  // console.log("scroll width:", barWidth);

  if (searchList.contains("is-opened")) {
    // retain scrollbar padding...
    navList.remove("noselect");
    clearAllBodyScrollLocks();
    searchList.remove("is-opened");
    navList.remove("search-opened");
    search.refresh();
  } else {
    // close menu if opened
    // closeMenu();
    // retain scrollbar padding...
    setTimeout(() => {
      navList.add("noselect");
      disableBodyScroll(searchWrap);
      // enableBodyScroll(searchWrap)
      searchList.add("is-opened");
      navList.add("search-opened");
    }, 300);
  }
};

if (searchWrap && searchBtns) {
  // backButton.addEventListener("click", (e) => {
  //   e.preventDefault();
  //   toggleShowSearch();
  // });

  Array.from(searchBtns).forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      toggleShowSearch();
    });
  });
}

const searchClient = algoliasearch(algoliaAppId, algoliaSearchKey);

// Global Search Dropdown...
const search = instantsearch({
  indexName: algoliaIndex,
  searchClient,

  // no results at first load...
  searchFunction(helper) {
    // console.log(helper);
    const container = document.querySelector("#results");
    container.style.display = helper.state.query === "" ? "none" : "";

    helper.search();
  },
});

search.addWidgets([
  searchBox({
    container: "#searchBox",
    placeholder: "Try ‘Interior design’ or ‘Whole Foods’",
    showReset: false,
    templates: {
      submit() {
        return "SEARCH";
      },
    },
    cssClasses: {
      root: "search-box",
      form: "search-form",
      input: "search-input",
      submit: "submit-button",
    },
    // update UI on each keystroke...
    searchAsYouType: true,
    queryHook(query, search) {
      search(query);
    },
  }),
  stats({
    container: "#stats",
    templates: {
      text(data) {
        if (data.query === "") {
          return;
        }

        let count = "";

        if (data.hasManyResults) {
          count += `${data.nbHits} results`;
        } else if (data.hasOneResult) {
          count += `1 result`;
        } else {
          count += `no result`;
        }

        return `${count} found in ${data.processingTimeMS}ms`;
      },
    },
  }),
  hits({
    container: "#hits",
    templates: {
      item(hit) {
        // console.log("hit", hit);
        return `
                <section class="flex flex-row flex-wrap md:flex-nowrap mb-10">
                    <div class="w-full order-2 md:order-1">
                        <p class="caps-xs text-turquoise">${
                          hit.section
                        }</p>
                        <a href="${hit.url}" class="block">
                            <h4 class="heading-4 text-gray-dark my-1.5">${
                              hit.parent ? `${hit.parent.verbFormat} >` : ""
                            } ${hit.title} ${
          hit.location ? `| ${hit.location}` : ""
        }</h4>
                        </a>
                        <p class="body-1">${hit.summary}</p>
                    </div>
                    <div class="w-full md:w-1/6 order-1 md:order-2 flex flex-col justify-center">
                        ${
                          hit.image
                            ? `
                            <a href="${hit.url}" class="block mb-3">
                              <img
                                  class="lazyload"
                                  data-src="${hit.image}"
                                  width="200px"
                              ><img>
                            </a>
                        `
                            : ""
                        }
                    </div>
                </section>
                `;
      },
    },
  }),
]);

search.start();

if (isHomePage) {
  // Home Search Block...
  const homeSearch = instantsearch({
    indexName: algoliaIndex,
    searchClient,

    // no results at first load...
    searchFunction(helper) {
      // console.log(helper);
      const container = document.querySelector("#homeResults");
      container.style.display = helper.state.query === "" ? "none" : "";

      helper.search();
    },
  });

  homeSearch.addWidgets([
    searchBox({
      container: "#homeSearchBox",
      placeholder: "Try ‘Interior design’ or ‘Whole Foods’",
      showReset: false,
      templates: {
        submit() {
          return "SEARCH";
        },
      },
      cssClasses: {
        root: "search-box",
        form: "search-form",
        input: "search-input",
        submit: "submit-button",
      },
      // update UI on each keystroke...
      searchAsYouType: true,
      queryHook(query, search) {
        search(query);
      },
    }),
    stats({
      container: "#homeStats",
      templates: {
        text(data) {
          const homeStatsElem = document.querySelector("#homeStats");
          const toggleClasses = (remove) => {
            if (remove) {
              if (homeStatsElem.classList.contains("no-results")) {
                homeStatsElem.classList.remove("no-results");
              }
            } else {
              if (!homeStatsElem.classList.contains("no-results")) {
                homeStatsElem.classList.add("no-results");
              }
            }
          };

          if (data.query === "") {
            toggleClasses(false);
            return;
          }

          let count = "";

          if (data.hasManyResults) {
            toggleClasses(true);
            count += `${data.nbHits} results`;
          } else if (data.hasOneResult) {
            toggleClasses(true);
            count += `1 result`;
          } else {
            toggleClasses(true);
            count += `no result`;
          }

          return `${count} found in ${data.processingTimeMS}ms`;
        },
      },
    }),
    hits({
      container: "#homeHits",
      templates: {
        item(hit) {
          // console.log("hit", hit);
          return `
                <section class="flex flex-row flex-wrap md:flex-nowrap mb-10">
                    <div class="w-full md:w-4/5 order-2 md:order-1">
                        <p class="caps-xs text-turquoise">${
                          hit.section
                        }</p>
                        <a href="${hit.url}" class="block">
                            <h4 class="heading-4 text-gray-dark my-1.5">${
                              hit.parent ? `${hit.parent.verbFormat} >` : ""
                            } ${hit.title} ${
            hit.location ? `| ${hit.location}` : ""
          }</h4>
                        </a>
                        <p class="body-1">${hit.summary}</p>
                    </div>
                    <div class="w-full md:w-1/6 order-1 md:order-2 flex flex-col justify-center">
                        ${
                          hit.image
                            ? `
                            <a href="${hit.url}">
                            <img
                                class="lazyload"
                                data-src="${hit.image}"
                                width="200px"
                            ><img>
                            </a>
                        `
                            : ""
                        }
                    </div>
                </section>
                `;
        },
      },
    }),
  ]);

  homeSearch.start();
}

export { toggleShowSearch };
