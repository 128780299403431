// import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
 
// import { homeSwiper } from '../modules/swiper'

// const options = {
//     reserveScrollBarGap: true,
// };

const main = document.getElementById('main')
const intro = document.querySelector('.intro-image')
const navBar = document.querySelector('header')
const searchMenu = document.querySelector('.search-wrap')
const homePopUp = document.querySelector('.home-feature-pop')
// const body = document.body
// const loader = document.querySelector('.loader')
// const loaderTitle = document.querySelector('.loader-title')

const loadHomePopUp = () => {
    if(homePopUp) {
        homePopUp.classList.add('loaded')
    }
}

window.addEventListener('load', () => {
    

        if(intro) {
            setTimeout(() => {
                // disableBodyScroll(body, options)
                main.classList.add('loaded')
                navBar.classList.add('loaded')
                intro.classList.add('loaded')
                setTimeout(() => {
                    loadHomePopUp()
                    // clearAllBodyScrollLocks()
                }, 6500);
            }, 1000);
        } else {
            // disableBodyScroll(body, options)
            main.classList.add('loaded')
            navBar.classList.add('loaded')
            loadHomePopUp()
            // clearAllBodyScrollLocks()
        }

        // if(homeSwiper) {
        //     homeSwiper.update()
        // }
        
        searchMenu.classList.add('loaded')
});