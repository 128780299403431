import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

document.querySelectorAll('a[href^="#"]').forEach(elem => {
  elem.addEventListener("click", e => {
    e.preventDefault();

    const clickedAnchorEl = document.querySelector(elem.getAttribute("href"));

    if (typeof clickedAnchorEl !== "undefined" && clickedAnchorEl !== null) {
      clickedAnchorEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  });
});

export default scrollTo;
