import { toggleShowSearch } from "./search";

const homeSearch = document.querySelector(".home-search");

const scrollToOpenSearch = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  setTimeout(() => {
    toggleShowSearch();
  }, 750);
};

// if(homeSearch) {
//     homeSearch.addEventListener('click', () => {
//         console.log('click')
//         scrollToOpenSearch()
//     })
// }
