import Swiper from "swiper/bundle";
import "swiper/css/bundle";

var banner = new Swiper(".banner", {
  slidesPerView: 1,
  loop: true,
  effect: "fade",
  speed: 1500,
  autoplay: {
    enabled: true,
    delay: 3000
  },
  navigation: {
    nextEl: '.swiper-next'
  }
});
