// CSS
import "../styles/main.css";

import { Fancybox } from "@fancyapps/ui";

// Alpine
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse"
import focus from "@alpinejs/focus"
import intersect from "@alpinejs/intersect"
Alpine.plugin(intersect)
Alpine.plugin(focus)
Alpine.plugin(collapse)
window.Alpine = Alpine
window.Alpine.start()
console.log(Alpine)


// Load in 3rd party libraries
import sal from "sal.js";
import "lazysizes";
import "lazysizes/plugins/bgset/ls.bgset";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

// Local modules
// import "./modules/masonry";
// import "./modules/modal";
import smoothScroll from "./modules/smoothScroll";
import "./modules/swiper";

// Local utils
import "./_utils/pre-loader";

// Local components
// import "./vue/vue-main";
import "./_components/expertise-nav";
import "./_components/accordion";
import "./_components/search";
import "./_components/home";

// Initialize global libraries
smoothScroll();
// Tobii photo gallery viewer
// Docs: https://github.com/rqrauhvmra/Tobii#options
// eslint-disable-next-line no-unused-vars
// const tobii = new Tobii({
//   captions: false,
//   zoom: false,
// });

// Sal (Scroll Animation Library)
// Docs: https://github.com/mciastek/sal#options
const scrollAnimation = sal({
  threshold: 0.4,
  once: true,
});

window.scrollAnimations = function () {
  console.log("update sal");
  scrollAnimation.reset();
};
